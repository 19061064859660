<template>
  <v-container fluid fill-height class="d-flex align-center justify-center">
    <v-card style="max-width: 600px; width: 100%">
      <v-toolbar :color="HRDC_BLUE_THEME_COLOR" dark class="text-h6">
        <v-btn
          icon
          class="hidden-xs-only text-white"
          :to="{
            name: 'PageGuestLoginFirstTime',
            params: {
              email: this.email,
            },
          }"
        >
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        First Time Login
      </v-toolbar>

      <v-card-text>
        <AError :api="api"></AError>
        <v-form>
          <v-text-field
            required
            v-model="form.email"
            :label="$t('model.prop.email')"
            readonly
            :disabled="true"
          ></v-text-field>
          <v-text-field
            required
            type="password"
            v-model="form.password"
            label="New Password"
          ></v-text-field>
          <v-text-field
            required
            type="password"
            v-model="form.passwordConfirmation"
            label="Confirm New Password"
            @keyup.native.enter="fetch"
          ></v-text-field>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn :loading="api.isLoading" color="primary" @click="fetch()"
          >Continue</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import Api from "@/objects/api";
import HrdcData from "@/services/hrdc/objects/globalData";

export default {
  props: {
    email: {
      type: String,
    },
  },
  data: () => ({
    form: {
      email: null,
      password: null,
      passwordConfirmation: null,
    },
    api: new Api(),
    hrdcData: new HrdcData(),
  }),
  created() {
    if (!this.email) this.$router.push({ name: "PageGuestLoginFirstTime" });
    this.form.email = this.$_.clone(this.email);
    this.api.setMethod(`POST`);
    this.api.setUrl(`${this.$service.sso}/v1/en/auth/login/password/set`);
    this.api.setCallbackCompleted((resp) => {
      this.$store.commit("updateAuth", resp);
      this.$router.push({ name: "PageConsoleDashboard" });
    });
  },
  methods: {
    fetch() {
      this.api.setParams(this.form);
      this.api.fetch();
    },
  },
};
</script>
